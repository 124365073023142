import { MainMenu, Spacer, utils } from '@agdt/agrotronic-react-components'
import { TimeZoneIndicator } from '@agdt/date'
import React from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { useHistory } from 'react-router-dom'
import { link } from 'router'
import styled from 'styled-components'
import { TUser } from 'types'
import FarmSelector from '../FarmSelector'
import { MenuItems } from '../useMenuItems'

const { screenResolutions } = utils

export type TProps = {
  items: MenuItems
  user: TUser
}

export default function DesktopMenu({ items, user }: TProps) {
  const t = useTranslate()
  const history = useHistory()

  const redirectToProfile = () => {
    history.push(link.profile)
  }

  return (
    <Container>
      <MainMenu
        items={items}
        noTooltip={window.innerWidth > parseInt(screenResolutions.SXGA)}
        onNavigate={history.push}
        t={t}
      />

      <Controls>
        <FarmSelector t={t} user={user} />

        <TimeZoneIndicator
          gmt={user.gmt}
          redirectToProfile={redirectToProfile}
          t={t as (key: string) => string}
        />
        <Spacer size="29px"/>
      </Controls>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: inherit;
  flex-grow: 1;
`

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
