import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/ru'
import moment from 'moment'

import { getNotificationTasks } from 'reducers/notificationTasks/actions'
import { TaskType } from 'reducers/notificationTasks/state'
import { AppDispatch } from 'reducers/store'
import { preLoaderStop } from 'reducers/user/actions'
import { userConfig, userInfo } from 'reducers/user/actions'
import { userLogin } from 'reducers/users/actions'
import { setAllowedsSensors } from 'reducers/users/actions'
import { TTranslate } from 'types'
import {
  getAllNotificationSettings,
  getAllNotificationTasks,
  getAllSensors,
  getRestrictions,
} from '../services/apiService/modules'
import { getStore } from '../stores/storesRegistry'

export class InteratorUnits {
  private play = true
  private successUserData = false
  private requests = {
    info: false,
    main: false,
  }

  constructor(private dispatch: AppDispatch) {
  }

  private createRequest = async (active = true) => {
    const targets2 = getStore('dictionaries').targets2

    try {
      await getStore('context').waitLoading()

      // Ждем значение от registers если повторный запрос - перезапрашиваем
      if(active) {
        await targets2.refresh()
      } else {
        await targets2.waitValue()
      }

      const targets2Data = await targets2.cookUsersUnits()
      await this.dispatch(userLogin(targets2Data))
    } catch(e){
      this.close()
      console.error(e)
    } finally {
      this.dispatch(preLoaderStop())
    }
  }

  private userConfig = async () => {
    const data = await getRestrictions() as {value: number, typeCode: string}[]

    const config = data.reduce((acc: Record<string, number>, D) => {
      acc[D.typeCode] = D.value

      return acc
    }, {})

    this.dispatch(userConfig(config))
  }

  private userData = async () => {
    const user = await getStore('dictionaries').currentUser.getValue()
    moment.locale(user.lang.toLowerCase())

    this.dispatch(userInfo(user))
    this.requests.info = true
    this.successUserData = true

    return user
  }

  private notificationTasks = async (accountType: string) => {
    // проверка типа учётной записи требуется пока в системе присутсвуют старые учётные записи типа Аккуант
    const data = accountType === 'user' ? await getAllNotificationSettings() : await getAllNotificationTasks()

    this.dispatch(getNotificationTasks(data as TaskType[]))
  }

  private getAllowedSensors = () => {
    getAllSensors()
      .then(data => {
        this.dispatch(setAllowedsSensors(data as object))
      })
      .catch(() => {
        this.successUserData = false
      })
  }

  iterateRequests = async () => {
    const userData = await this.userData()
    await this.userConfig()

    if(userData){
      await this.notificationTasks(userData.accountType)
    }

    if(!this.successUserData) {
      return
    }

    const next = async (active = true) => {
      await this.createRequest(active)

      setTimeout(async () => {
        if(this.play) {
          await next(true)
        }
      }, 600000)
    }

    await next(false)
    this.getAllowedSensors()
  }

  close = () => {
    this.play = false
  }
}

type Registers = {
  STATUS: number
  STATUS_SH: number
  STATUS_TS: number
  LATITUDE: number
  LONGITUDE: number
  ACTIVE: number
  ADAPTER: number
  SPEED: number
  FUEL: number
  FUEL_MAX: number
  FUEL_CAL: number
  CURR_PERF: number
  FIRST_MOTOR: number
} & Record<string, number>

export const cookReqisters = (registers: Registers, translate: TTranslate) => {
  const result = Object.keys(registers).reduce((acc: Record<string, unknown>, key) => {
    if(key === 'STATUS' || key === 'STATUS_SH' || key === 'STATUS_TS') {
      acc['STATUS'] = {
        color:
          registers[key] === 0
            ? '#BCBCBC'
            : registers[key] === 1
              ? '#06a9f5'
              : registers[key] === 2
                ? '#202020'
                : registers[key] === 3
                  ? '#d10a40'
                  : registers[key] === 4
                    ? '#78C800'
                    : registers[key] === 5
                      ? '#d61fd6'
                      : '#F37203',
        measure: '',
        name   : translate('Status at now'),
        value  : registers[key],
        valueF : translate('M' + registers[key]),
      }
    } else if(key === 'LATITUDE' || key === 'LONGITUDE') {
      acc[key] = {
        value: registers[key],
      }
    } else if(key === 'ACTIVE') {
      acc['ACTIVE'] = {
        name  : translate('last_data'),
        value : registers[key],
        time  : moment.unix(registers[key] / 1000).format('YYMMDDHHmmss000'),
        valueF: moment.unix(registers[key] / 1000).format('DD.MM.YY HH:mm:ss'),
      }
    } else if(key === 'ADAPTER') {
      acc['ADAPTER'] = {
        name   : translate('reaper'),
        value  : registers[key],
        measure: translate('m'),
        valueF : `${registers[key]} ${translate('m')}`,
      }
    } else if(key === 'SPEED') {
      acc['SPEED'] = {
        brName : translate('speed'),
        max    : 100,
        measure: translate('km/h'),
        min    : 0,
        name   : translate('Move speed of combine'),
        time   : null,
        value  : registers[key],
        valueF : `${registers[key] ? registers[key].toFixed(2) : '-'} ${
          registers[key] ? translate('km/h') : ''
        }`,
      }
    } else if(key === 'FUEL') {
      acc['FUEL'] = {
        brName : translate('fuel'),
        max    : 100,
        measure: '%',
        min    : 0,
        name   : translate('Level fuel in tank'),
        value  : registers[key] !== null ? registers[key] : 0,
        valueF : `${registers[key] !== null ? registers[key] : 0} %`,
      }
    } else if(key === 'FUEL_MAX') {
      acc['FUEL'] = {
        brName : translate('fuel'),
        max    : registers['FUEL_MAX'],
        measure: translate('l'),
        min    : 0,
        name   : translate('Level fuel in tank'),
        value  : registers['FUEL_CAL'],
        valueF : `${registers['FUEL_CAL']} ${translate('l')}`,
      }
    } else if(key === 'CURR_PERF') {
      acc['CURR_PERF'] = {
        brName : translate('Performance at now'),
        max    : 100,
        measure: translate('ha/h'),
        min    : 0,
        name   : translate('Performance at now'),
        time   : null,
        value  : registers[key],
        valueF : registers[key] ? `${registers[key]} ${translate('ha/h')}` : '-',
      }
    } else if(key === 'FIRST_MOTOR') {
      acc['FIRST_MOTOR'] = {
        brName : translate('inclusion_DVS'),
        measure: translate('ms'),
        name   : translate('inclusion_DVS'),
        time   : registers[key]
          ? Date.parseDateTimeFromApi(String(registers[key])).format('YYMMDDHHmmss000')
          : '-',
        value : registers[key],
        valueF: registers[key]
          ? Date.parseDateTimeFromApi(String(registers[key])).format('HH:mm:ss')
          : '-',
      }
    } else {
      acc[key] = {
        value: registers[key],
      }
    }

    return acc
  }, {})

  if(!result.STATUS) {
    result['STATUS'] = {
      color  : '#b1b1b1',
      measure: '',
      name   : translate('Status at now'),
      value  : 0,
      valueF : translate('M0'),
    }
  }

  if(!result.ACTIVE) {
    result['ACTIVE'] = {
      name  : translate('last_date'),
      time  : 0,
      value : 0,
      valueF: '-',
    }
  }

  return result
}
