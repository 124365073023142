import { getSystemTimeZone } from '@agdt/date'
import { isNil } from 'ramda'
import { LANG_RU } from '../../../constants/lang'

/*@ts-expect-error*/
export const parseFromApi = response => {
  return {
    ...response,
    accountType      : 'user',
    email            : response.operatorEmail,
    fullName         : response.operatorFirstName,
    gmt              : isNil(response.gmt) ? getSystemTimeZone() : response.gmt,
    gmtIsLocal       : isNil(response.gmt),
    lang             : response.lang || LANG_RU,
    lastName         : response.operatorSurname,
    middleName       : response.operatorSecondName || '',
    name             : `${response.operatorFirstName} ${response.operatorSurname}`,
    operatorTelNumber: response.operatorTelNumber ? response.operatorTelNumber.toString() : undefined,
    photo            : response.photo ? `data:image/jpeg;base64,${response.photo}` : undefined,

    //telNumber        : response.operatorTelNumber ? response.operatorTelNumber.toString() : undefined,
  }}
