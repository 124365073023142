import { CheckBox, formats, icons, Select, SelectItem, utils } from '@agdt/agrotronic-react-components'
import { C_DEFAULT_TZ_INDEX, getTimeZone, timeZones } from '@agdt/date'
import Tippy from '@tippyjs/react'
import { isEmpty } from 'ramda'
import React, { FC,useCallback, useState } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { UserInfoType } from 'reducers/user/state'
import styled from 'styled-components'
import { emailValidators, notEmptyValidator, passwordValidators, phoneValidator, validate } from './common/validators'
import { LoginInput } from './LoginInput'
import { MfaSwitch } from './MfaSwitch'
import { UserPhoneEmailInputs } from './UserPhoneEmailInputs'
import { ValidatedInputWrapper } from './ValidatedInputWrapper'

const { phone } = formats
const { TopMenuHelpIcon } = icons

const { screenResolutions } = utils

type Props = {
  user: UserInfoType
  disabled: boolean
  handleSave: (field: string, value: unknown) => void
  isFieldSave: Record<string, boolean>
  updateUserInfo: (value: UserInfoType) => void
}

export const Info: FC<Props> = ({ disabled, handleSave, isFieldSave, updateUserInfo, user }) => {
  const [GMTOpen, setGMTOpen] = useState(false)
  const handleGMTOpen = () => setGMTOpen(!GMTOpen)
  const t = useTranslate()
  const notEmptyValidate = validate(notEmptyValidator)
  const preparedTimeZone = timeZones.slice(0, C_DEFAULT_TZ_INDEX + 1).reverse()
  const currentTimeZone = getTimeZone(user.gmt)

  const passwordValidate = useCallback((value: string): string | undefined => {
    if(isEmpty(value)) {
      return
    }

    return validate(passwordValidators)(value)
  }, [])

  const handleUpdateUserInfo = useCallback((fieldName, value) => {
    updateUserInfo({
      ...user,
      [fieldName]: value,
    })
  }, [updateUserInfo, user])

  const onClickZone = (value: string | null) => () => handleSave('gmt', value)
  const handleSetLocalTimezone = onClickZone(user.gmtIsLocal ? user.gmt.toString() : null)

  return <Block>
    <Title>{t('profile')}</Title>

    <InputBlocks>
      <ValidatedInputWrapper
        dataIsSave={isFieldSave['operatorSurname']}
        disabled={disabled}
        disableClear
        fieldName={'operatorSurname'}
        handleSave={handleSave}
        initValue={user.lastName}
        label={t('Surname')}
        required
        testid='profile-info__surname'
        validator={notEmptyValidate}
      />

      <ValidatedInputWrapper
        dataIsSave={isFieldSave['operatorFirstName']}
        disabled={disabled}
        disableClear
        fieldName={'operatorFirstName'}
        handleSave={handleSave}
        initValue={user.fullName}
        label={t('Name')}
        required
        testid='profile-info__name'
        validator={notEmptyValidate}
      />

      <ValidatedInputWrapper
        dataIsSave={isFieldSave['operatorSecondName']}
        data-testid='profile-info__middlename-input'
        disabled={disabled}
        disableClear
        fieldName={'operatorSecondName'}
        handleSave={handleSave}
        initValue={user.middleName}
        label={t('middle name')}
        testid='profile-info__middlename'
      />

      {user.accountType === 'user' &&
        <LoginInput
          dataIsSave={isFieldSave['operatorLogin']}
          disabled={disabled}
          disableClear
          fieldName={'operatorLogin'}
          handleSave={handleSave}
          initValue={user.operatorLogin}
          label={t('Login')}
          required
          testid='profile-info__login'
        />}

      {/*для пользовательской учётной записи требуется подтверждение телефона или почты */}
      {user.accountType === 'user' &&
        <UserPhoneEmailInputs
          disabled={disabled}
          handleUpdateUserInfo={handleUpdateUserInfo}
          t={t}
          user={user}
        />
      }

      {/* TODO после отключения учётных записей предприятий нужно удалить эти поля */}
      {user.accountType === 'account' && <>
        <ValidatedInputWrapper
          dataIsSave={isFieldSave['telNumber']}
          disableClear
          disabled={disabled}
          fieldName={'telNumber'}
          formatter={phone.phoneMask}
          handleSave={handleSave}
          initValue={user.telNumber}
          label={t('Phone')}
          postProcessValue={phone.onlyDigits}
          required
          testid='profile-info__account-phone'
          validator={validate(phoneValidator)}
        />

        <ValidatedInputWrapper
          dataIsSave={isFieldSave['email']}
          disableClear
          disabled={disabled}
          fieldName={'email'}
          handleSave={handleSave}
          initValue={user.email}
          label="E-mail"
          required
          testid='profile-info__account-email'
          validator={validate(emailValidators)}
        />
      </>}
    </InputBlocks>

    <BottomWrapper>
      <Select
        disableClear
        disabled={disabled || isFieldSave['gmt'] || user.gmtIsLocal}
        isPopupOpen={GMTOpen}
        label={t('Time zone')}
        onHidePopup={handleGMTOpen}
        onShowPopup={handleGMTOpen}
        title={`UTC ${currentTimeZone.tz} (${t(currentTimeZone.name)})`}
      >
        {preparedTimeZone.map(zone =>
          <SelectItem
            key={zone.tz}
            onClick={onClickZone(zone.tz)}
          >
            {`UTC ${zone.tz} (${t(zone.name)})`}
          </SelectItem>,
        )}
      </Select>

      <CheckboxWrapper>
        <CheckBox
          checked={user.gmtIsLocal}
          disabled={isFieldSave['gmt']}
          label={t('Use system time')}
          onChange={handleSetLocalTimezone}
        />

        <StyledTippy
          content={t('The time zone will be automatically synchronized')}
          interactive
          placement='right'
        >
          <HelpIconWrapper><TopMenuHelpIcon color="#CCCCCC" size="20px"/></HelpIconWrapper>
        </StyledTippy>
      </CheckboxWrapper>

      {user.accountType === 'user' && <>
        <ValidatedInputWrapper
          dataIsSave={isFieldSave['operatorPassword']}
          data-testid='profile-info__password-input'
          disabled={disabled}
          disableClear
          fieldName={'operatorPassword'}
          handleSave={handleSave}
          initValue={''}
          label={t('Password')}
          testid='profile-info__password'
          validator={passwordValidate}
        />

        <MfaSwitch
          handleUpdateUserInfo={handleUpdateUserInfo}
          user={user}
        />
      </>}
    </BottomWrapper>
  </Block>
}

const Block = styled.div`
  background-color: #fff;
  padding: 24px 24px 52px 24px;
  border: 1px solid #E2E5EC;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: ${screenResolutions.SXGA}) {
    padding: 24px;
  };
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 1.6em;
  margin-bottom: 17px;
`

const InputBlocks = styled.div`
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 24px;
  @media (max-width: ${screenResolutions.SXGA}) {
    grid-template-columns: repeat(3, 1fr);
  };
  @media (max-width: ${screenResolutions.XGA}) {
    grid-template-columns: auto;
  };
`

const BottomWrapper = styled.div`
  margin-top: 16px;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 24px;
  
  & > * {
    grid-column: span 2;
  }
`

const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
`

const HelpIconWrapper = styled.div`
  margin-left: 8px;
  cursor: pointer;
  padding-top: 2px;
`

const StyledTippy = styled(Tippy)`
  box-shadow: 0 3.75px 11px 0 rgba(0, 0, 0, 0.19), 0 0.5px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #323232 !important;
  border-radius: 4px !important;

  .tippy-content {
    padding: 4px 8px;
    color: #FEFEFE;

    > div {
      font-size: 14px;
      font-weight: 400;
    }
  }
`
