import { TabContent, TabItem } from '@agdt/agrotronic-react-components'
import { Spin } from 'antd'
import { __, filter, find, isEmpty, map, path, pipe, prop, uniq, whereEq } from 'ramda'
import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import { getFarmUnloads, getTransports } from 'services/apiService/modules'
import styled, { css } from 'styled-components'
import { useUserTimezone } from '../../hooks'
import {
  clearReport,
  pushUnloadingCarrierReport,
  pushUnloadingReport,
  toggleUnloadingReport,
} from '../../reducers/reports/actions'
import { getCarrierUnloads, getTargets2 } from '../../services/apiService/modules'
import { errorMessage } from '../../services/notification'
import { getStore } from '../../stores/storesRegistry'
import FilterControl, { FilterControlConfig } from '../../ui/Filter/filterControl'
import InitFilter from '../../ui/Filter/initFilter'
import WithNoItemsForReportPlaceholder from './common/WithNoItemsForReportPlaceholder'
import { UnloadingResult } from './result/Unloading'

const styles = {
  wrapCenter: { display: 'flex', justifyContent: 'center' },
  button    : {
    marginBottom: '10px',
    background  : '#78C800',
    border      : '1px solid #78C800',
  },
}

const ReportUnloading: FC<FilterControlConfig> = props => {
  const t = useTranslate()
  const report = useSelector((state: AppStateType) => state.reports.get('unloading'))

  //@ts-expect-error
  const load = report.get('load')

  //@ts-expect-error
  const reportFromReport = report.get('report')

  //@ts-expect-error
  const carrierReport = report.get('carrierReport')

  const tabs = useMemo(() => [
    { id: 'sht', label: t('sht') },
    { id: 'ts', label: t('transports') },
  ], [t])

  const [currentTab, setCurrentTab ] = useState(tabs[0])

  const filterIsEmpty = useMemo(() =>
    isEmpty(props.filter.find(I => I.type === 'checkbox-machine')?.items), [props.filter])

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={filterIsEmpty}
        text={t('Select a different report or change the filtering conditions')}
        title={t('There is no data on which to build this report')}
      >
        <FilterControl
          button={true}
          disableButton={load}
          translate={t}
          {...props}
        />

        {load &&
          <div style={styles.wrapCenter}>
            <Spin />
          </div>
        }

        {reportFromReport && !isEmpty(reportFromReport) && <>
          <TabsContainer>
            {tabs.map(tab =>
              <StyledTabItem
                active={tab.id === currentTab.id}
                key={tab.id}
                onSelect={setCurrentTab}
                tab={tab}
                noBottomBorder={true}
              />,
            )}
          </TabsContainer>
          <StyledTabContent>
            {currentTab.id === tabs[0].id && reportFromReport &&
              <UnloadingResult units={reportFromReport} />
            }

            {currentTab.id === tabs[1].id && carrierReport &&
              <UnloadingResult units={carrierReport} />
            }
          </StyledTabContent>
        </>}

        {isEmpty(reportFromReport) && <NoDataWrapper>{t('no data')}</NoDataWrapper>}
      </WithNoItemsForReportPlaceholder>
    </div>
  )
}


//@ts-expect-error
const getTransportModel = (code, transport) => pipe(find(whereEq({ code })), prop('name'))(transport)

export default InitFilter({
  default: [
    {
      id      : 0,
      items   : [],
      name    : 'machine',
      result  : [],
      section : 'machine',
      type    : 'checkbox-machine',
      validate: true,
    },
    {
      id      : 2,
      name    : 'select_a_date',
      result  : [],
      section : 'time',
      type    : 'date',
      validate: true,
    },
  ],

  callback: data => async dispatch => {
    dispatch(toggleUnloadingReport())
    const t = getStore('context').t
    const { getDateWithUserTimeZone } = useUserTimezone()
    const formatDateTime = pipe(Date.parseDateTimeFromCalendarApi, getDateWithUserTimeZone)

    try {
      const units = await getTargets2({ registers: ['TYP_HARVESTER_NUMBER'] })
      const transport = await getTransports()

      const farmUnloads = await getFarmUnloads({
        from: formatDateTime(data.time[0].from),
        id  : map(prop('id'), data.machine),
        to  : formatDateTime(data.time[0].to),
      })

      //@ts-expect-error
      const carrierIds = pipe(map(prop('carrier')), uniq, filter(Boolean))(farmUnloads)

      //@ts-expect-error
      const report = farmUnloads.reduce((acc, unload) => {
        const unit = {
          ...unload,
          agriculture: isEmpty(unload.agriculture) ? t('Unknown agriculture') : unload.agriculture,
          model      : getTransportModel(unload.carrier, transport),
          number     : unload.carrier < 1 ? '' : unload.carrier,
        }

        //@ts-expect-error
        const unitPath = path(__, units[unload.imei])

        if(acc[unload.imei]) {
          acc[unload.imei].uploads = acc[unload.imei].uploads + unload.uploads
          acc[unload.imei].details.push(unit)
          return acc
        }

        acc[unload.imei] = {
          details: [unit],
          id     : unload.imei,
          imei   : unload.imei,

          //@ts-expect-error
          model: unitPath(['model']),

          //@ts-expect-error
          name: unitPath(['name']),

          //@ts-expect-error
          number : unitPath(['registers', 'TYP_HARVESTER_NUMBER']) || unitPath(['name']),
          uploads: unload.uploads,
        }

        return acc
      }, {})

      dispatch(pushUnloadingReport(report))

      if(!isEmpty(carrierIds)) {
        const carrierUnloadsParams = {
          from: formatDateTime(data.time[0].from),

          //@ts-expect-error
          id: pipe(map(prop('carrier')), uniq, filter(Boolean))(farmUnloads),
          to: formatDateTime(data.time[0].to),
        }

        const carrierUnloads = await getCarrierUnloads(carrierUnloadsParams)

        //@ts-expect-error
        const carrierReport = carrierUnloads.reduce((acc, unload) => {
          //@ts-expect-error
          const unitPath = path(__, units[unload.imei])

          const unit = {
            ...unload,
            imei: unload.imei,

            //@ts-expect-error
            model: unitPath(['model']),

            //@ts-expect-error
            name: unitPath(['name']),

            //@ts-expect-error
            number: unitPath(['registers', 'TYP_HARVESTER_NUMBER']) || unitPath(['name']),
          }

          if(acc[unload.carrier]) {
            acc[unload.carrier].details.push(unit)
            return acc
          }

          if(unload.carrier) {
            acc[unload.carrier] = {
              ...unload,
              agriculture: isEmpty(unload.agriculture) ? t('Unknown agriculture') : unload.agriculture,
              details    : [unit],
              id         : unload.carrier,
              model      : getTransportModel(unload.carrier, transport),
              number     : unload.carrier < 1 ? '' : unload.carrier,
            }
          }

          return acc
        }, {})

        dispatch(pushUnloadingCarrierReport(carrierReport))
      }
    } catch(e) {
      errorMessage(e)
    }

    dispatch(toggleUnloadingReport())
  },

  clear: () => dispatch => {
    dispatch(clearReport(ReportType.Unloading))
  },

  //@ts-expect-error
})(ReportUnloading)

const NoDataWrapper = styled.div`
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #888886;
`

const StyledTabContent = styled(TabContent)`
  border: none;
  height: auto;
  overflow-y: unset;
  padding: 8px 0 0 0;
  background: none;
`

const StyledTabItem = styled(TabItem)`
  border: none;
  font-size: 16px;
  font-weight: 700;
  position: inherit;
  padding: 0;
  margin-right: 32px;
  cursor: pointer;
  background: none;

  ${({ active }) => active
    ? css`
      border-bottom: 2px solid #D10029;
      margin-top: 2px;
  ` : css`
      background-color: inherit;
      color: rgba(0, 0, 0, .36);
  `}

  > div{
    display: none;
  };
`

const TabsContainer = styled.ul`
  display: flex;
  flex: 1 0 50px;
  height: 50px;
  width: 100%;
  padding: 0 0 0 24px;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
`
