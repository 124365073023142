import { getSystemTimeZone } from '@agdt/date'
import { isNil } from 'ramda'
import { LANG_RU } from '../../../constants/lang'

/*@ts-expect-error*/
export const parseAccountFromApi = response => {
  return {
    ...response,
    accountType: 'account',
    gmt        : isNil(response.gmt) ? getSystemTimeZone() : response.gmt,
    gmtIsLocal : isNil(response.gmt),
    lang       : response.lang || LANG_RU,
    photo      : response.photo ? `data:image/jpeg;base64,${response.photo}` : undefined,
  }}
