import { hooks,UserMenu as GenericUserMenu } from '@agdt/agrotronic-react-components'
import { isEmpty } from 'ramda'
import React, { useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import { IntlActions, withTranslate } from 'react-redux-multilingual'
import { useHistory } from 'react-router-dom'
import { switchHelp } from 'reducers/settings/actions'
import { AppStateType } from 'reducers/store'
import { userInfo } from 'reducers/user/actions'
import { userLogout } from 'reducers/users/actions'
import { link } from 'router'
import { editInfo } from 'services/apiService/modules'
import { updateCurrentUser } from 'services/apiService/modules'
import { errorMessage } from 'services/notification'
import { getStore } from 'stores/storesRegistry'
import { translations } from 'translations'
import { TLang, TUser } from 'types'

/*@ts-expect-error*/
function UserMenu(props) {
  const history = useHistory()
  const curLang = useSelector<AppStateType, TLang>((state: AppStateType) => state.Intl.locale as TLang)

  const onChangeLang = useCallback(lang => {
    if(!props.isDemoMode) {
      const prevLang = curLang

      const targetAPIFn = props.info.accountType === 'user' ? updateCurrentUser : editInfo

      targetAPIFn({ lang }).then(response => {
        getStore('dictionaries').currentUser.setCurValue(response as TUser)

        hooks.setLang(lang)

        props.changeLang(lang)
      }).catch(error => {
        props.changeLang(prevLang)
        errorMessage(error)
      })
    }
  }, [props])

  return (
    !isEmpty(props.info) ? <GenericUserMenu
      changeLang={onChangeLang}
      gotoLink={history.push}
      handleHelpSwitch={props.switchHelp}
      isHelpSwitchChecked={props.isHelpChecked}
      languages={translations}
      links={link}
      logout={userLogout}
      t={props.translate}
      userInfo={props.info}
    /> : <></>
  )
}

/*@ts-expect-error*/
const mapStateToProps = state => ({
  isDemoMode   : state.user.demoMode,
  isHelpChecked: state.settings.help,
})

/*@ts-expect-error*/
const mapDispatchToProps = dispatch => ({

  /*@ts-expect-error*/
  changeLang: lang => dispatch(IntlActions.setLocale(lang)),

  /*@ts-expect-error*/
  saveUserInfo: data => dispatch(userInfo(data)),

  switchHelp: () => dispatch(switchHelp()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(UserMenu))
