/* eslint-disable */
import notificationTasks from './notificationTasks/en'
import { timeZonesEn } from '@agdt/date'

export default {
  ...timeZonesEn,

  // Validation
  "Confirmation code": "Confirmation code",
  "Approve": "Confirm",
  "Send code": "Send code",
  "Wrong code format": "Incorrect code format",
  "Send new code": "Send new code",
  "Enter a number or Email": "Enter a number or Email",
  "Validation error": "Validation error",
  "Error during validation": "Error during validation",
  "This is a required field": "This is a required field",
  "Password cannot be less than 8 characters": "Password must contain at least 8 characters (uppercase and lowercase, numbers and other characters)",
  "At least 6 characters, only numbers and Latin letters": "At least 6 characters, only numbers and Latin letters",
  "Wrong email or phone number": "Email or phone number entered incorrectly",
  "Confirm password mismatch": "Passwords don't match",
  "Password restored": "Password restored",
  "Password success changed":"Password has been successfully restored. You can use your new password to log in",

  // Notification Tasks
  notificationTasks,

  // Common
  "To generate the report, you must specify the email address": "To generate the report, you must specify the email address",
  "set email": "Set email",
  "Confirmation code sent to the email/phone number": "The confirmation code has been sent to the specified phone/email",
  "Reset password": "Password recovery",
  "Password reset": "Password reset successful",
  "Repeat password": "Repeat password",
  "New password": "New password",
  "Next": "Next",
  "To signin": "To authorization",
  "Phone / Email": "Phone / Email",
  "OK": "ОК",
  "Open": "Select files",
  "Import data from USB": "Import data from USB",
  "Wrong file format": "The file format is not supported",
  "import completed": "Data imported",
  "The geozone should not self-crossing": "The geozone should not self-crossing",
  more: "Details",
  sign_out: "Sign out",
  helpers: "Helpers",
  desktop: "Desktop",
  "map (new)": "Map (new)",
  myMachines: "My machines",
  tracking: "Tracking",
  watch: "Watch",
  notifications: "Notifications",
  Notifications: "Notifications",
  "Not found": "Not found",
  settings: "Settings",
  sortByQuantity: "Sort by quantity",
  map: "Map",
  activity: "Activity",
  removed: "Removed",
  name_of_the_farm: "Name of the farm",
  machines_total: "Total machines",
  total: "Total",
  active: "Active",
  warning: "Warning",
  farming_name: "Farming name",
  machines_quantity: "Machines quantity",
  activities: "Activities",
  fuel: "Fuel",
  conservants: "Preservative application system",
  machine: "Machine",
  amountOfHours: "The amount of hours",
  inclusion_DVS: "Engine start",
  last_data: "Last info",
  speed: "Speed",
  speed_at_now: "Current speed",
  expense_of_fuel: "Fuel consumption",
  area_cleaned: "Application area",
  performance: "Performance",
  watching: "Tracking",
  status: "Status",
  type_of_machine: "Type of machine",
  farming: "Farms",
  day: "Day",
  month: "Month",
  year: "Year",
  today: "Today",
  week: "Week",
  date: "Date",
  select_a_date: "Select a date",
  track: "Track",
  accept: "Accept",
  range_of_date: "Range of time",
  drain: "Drain",
  refuel: "Refuel",
  indications_of_work: "Indications of work",
  consolidated_statement_about_machines: "Consolidated statement about machines",
  events_a_o: "Events a/o",
  export: "Export",
  model: "Model",
  time: "Time",
  status_of_message: "Status of message",
  category: "Category",
  found: "Found",
  selecting_date: "Selecting date",
  analysis: "Analysis",
  use_of_time: "Use of time",
  report_about_machine: "Report about machine",
  fuel_report: "Report about fuel",
  harvest: "Application",
  driving_mode: "In motion",
  work_engine_in_no_movement: "Idle with running engine",
  liters: "liters",
  average_speed_move_in_harvest: "Average speed motion",
  machines: "machines",
  farmings: "Farms",
  geozones: "Geozone",
  search: "Search",
  search_for: "Search for",
  search_for_geozone: "Search for geozone",
  params_of_report: "Params of report",
  format_of_file: "Format of file",
  type: "Type",
  events: "Events",
  event: "Event",
  count: "Count",
  duration: "Duration",
  date_of_start: "Date of start",
  time_of_start: "Time of start",
  time_of_end: "Time of end",
  cancelChanges: "Changes canceled",
  saveProfileWait: "Information is saved",
  saveProfileDone: "Information saved",
  saveProfileError: "An error occurred while saving",
  savePhotoWait: "Photo is being saved",
  savePhotoDone: "Photo saved",
  deleteProfileWait: "Information is deleted",
  deleteProfileDone: "Information deleted",
  pleaseWait: "Please wait",
  deletePhoto: "Photo deleted",
  photoTooLarge: "Photo too large",
  maxPhotoSize: "Maximum photo size: 2Mb",
  wrongPhotoFormat: "Unsupported photo format",
  supportedPhotoFormats: "Supported photo formats: JPEG, JPG, PNG",
  "A final report about harvesting": "A final report about harvesting",
  "A daily report about harvesting (compact)": "A daily report about harvesting (compact)",
  "A final report about used time": "A final report about used time",
  "An overall report about operation time": "An overall report about operation time",
  "Daily Cleaning/Work Report (KYK)": "Daily Cleaning/Work Report (HOW)",
  "A final report about harvesting (compact)": "A final report about harvesting (compact)",
  "A final report about machine": "A final report about machine",
  "A daily report about used time": "A daily report about used time",
  "A daily report about harvesting": "A daily report about harvesting",
  "A daily report about machine": "A daily report about machine",

  "A report about machine": "A report about machine",
  "A final report agricultural operations": "A final report agricultural operations",
  "A detail report agricultural operations": "A detail report agricultural operations",
  "A report of fuel": "A report of fuel",
  "Filling/Drain": "Filling/Drain",

  export_of_report: "Export of report",
  export_of_reports: "Export of reports",
  "Please select unit(s) and time period": "Please select unit(s) and date/period",
  by_model: "By model",
  by_active: "By active",
  not_of_data: "Not of data",
  selecting: "Selecting all",
  deselecting: "Deselecting all",
  "Navigation menu by pages": "Navigation menu by pages",
  "profile settings": "profile settings",
  "farmings list": "List of farmings",
  "Change zoom of map Can use scroll of mouse or multitouch": "Change zoom of map. Can use scroll of mouse or multitouch",
  "Switch on/ switch off fullscreen mode": "Switch on/ switch off fullscreen mode",
  "Show center of a map": "Show center of a map",
  "The selection type a map": "The selection type a map",
  "The map show all machines Click on machine, to open information about this machine": "The map show all machines. Click on machine, to open information about this machine",
  "Cost fuel for regimes all machines": "Cost fuel for regimes all machines",
  "Percent of time, when machines had active regime": "Percent of time, when machines had active regime",
  "serial n": "Serial №",
  "Start of fuel": "Start of fuel",
  "End of fuel": "End of fuel",
  filling: "Filling of fuel",
  drain_of_day: "Drain of fuel",
  "summFuel": "Fuel consumption",
  "summFuelMove": "Fuel consumption in transport mode",
  "summFuelComb": "Fuel consumption in harvest mode",
  "summFuelStand": "Fuel consumption in stop",
  "fuelHour": "avg fuel",
  "engineTime": "Engine time",
  "engineTimeMove": "Engine time in transport mode",
  "engineTimeComb": "Engine time in harvest mode",
  "engineTimeStop": "Engine time in stop",
  "phone": "Phone",
  "clear": "Clear",
  "clear filter": "Clear filter",
  "fixed options of filter": "Fixed options of filter",
  "M0": "No connection",
  "M2": "Stop engine",
  "M5": "Idle with work a",
  "M6": "Idle with work aggregates and full tank",
  "M1": "In motion",
  "M3": "Idle with running engine",
  "M4": "Application",
  Idle: "Idle",
  UPLOAD: "Unloading",
  BUNKER_FULL: "Grain tank full",
  details: "Detailing",
  FILING: "Filling",
  DRAIN: "Drain",
  All: "All",
  "All machines": "All machines",
  "harvesting machinery": "Harvesting machimery",
  "sowing machinery": "Sowing machinery",
  "tractor": "Tractors",
  "clear_filter": "Clear filter",
  critical: "Critical",
  not_critical: "Not critical",
  informational: "Informational",
  service: "Service",
  engine: "Engine",
  fuel_system: "Fuel system",
  adapter_reaper: "Adapter and reaper",
  reaper: "reaper",
  inclined_camera_feeder: "Inclined camera and feeder",
  cabin: "Cabin",
  thresher: "Thresher and Straw Chopper", //Thresher ZUK and chopper KUK
  cleaning: "Cleaning",
  chassis: "chassis",
  hopper: "Grain tank",
  stacker: "Straw Chopper and Stacker",
  hydraulics: "Hydraulics",
  electrics: "Electrics and electronics",
  notification: "Notification",
  harvesting: "Harvesting",
  hopper_full: "Grain tank full",
  unloading: "Unloading",
  "Loading data": "Loading data",
  downtime: "Idle time",
  "idle_time_<_1": "idle time up to 1 min",
  "idle_time_>_1": "idle time more then 1 min",
  "stop_<_1": "stop less than 1 minute",
  "stop_1_10": "stop from 1 to 10 minutes",
  "stop_>_10": "stop more than 10 minutes",
  summary: "Summary",
  detail: "Detail",
  agriculture: "Agriculture",
  rating: "Rating",
  "ON_UPLOAD": "Upload",
  "ON_100": "Grain tank full",
  "group": "Group",
  "single": "Single",
  "Set a date": "Set a date",
  "Indicators at moment": "Indicators at moment",
  "Technique in testing": "Technique in testing",
  "Maintenance": "Maintenance",
  "Aggregates": "Aggregates",
  "Status at now": "Status at now",
  "Move speed of combine": "Move speed of combine",
  "Level fuel in tank": "Level fuel in tank",
  "Performance at now": "Performance at now",
  "is active": "active",
  "not active": "not active",
  "download excel": "Download (excel)",
  "report cleaning": "Report of cleaning",
  "make graphics": "Make graph",
  "Contact form": "Contact form",
  "Reasons contact": "Reasons contact",
  "Service": "Service",
  "Contact phone": "Contact phone",
  "Message": "Message",
  "Serial number machine": "Serial number machine",
  "Type technics": "Type technics",
  "submit": "Submit",
  "profile": "Profile",
  "Load photo": "Load photo",
  "noname": "Name isn't found",
  "noemail": "E-mail isn't found",
  "language": "Language",
  "ru": "Русский",
  "en": "English",
  "fr": "Le français",
  "de": "Deutsch",
  "Serial number search": "Serial number search",
  "For the current day": "For the current day",
  "feedback": "Feedback",
  "period": "Period",
  "sprayers": "Sprayers",
  "mode": "Mode",
  "level fuel, l": "Level fuel, l",
  "fuel graphics": "Fuel graph",
  "creating geozones": "Creating geozones",
  "area": "Area",
  "Last point": "Last point",
  "Long": "Long",
  "km": "km",
  "Save geozone": "Save geozone",
  "Active point": "Active point",
  "Continue": "Continue",
  "Delete point": "Delete point",
  "shape": "Shape file upload",
  "Turning on the display of directions on the map": "Turning on the display of directions on the map",
  "Select map tile": "Select map tile",
  "Geozone edit": "Geozone edit",
  "New geozone": "New geozone",
  "Name geozone": "Name geozone",
  "Do not display": "Do not display",
  "Convective precipitation": "Convective precipitation",
  "Precipitation intensity": "Precipitation intensity",
  "Accumulated precipitation": "Accumulated precipitation",
  "Accumulated precipitation - rain": "Accumulated precipitation - rain",
  "Accumulated precipitation - snow": "Accumulated precipitation - snow",
  "Depth of snow": "Depth of snow",
  "Wind speed at an altitude of 10 meters": "Wind speed at an altitude of 10 meters",
  "Joint display of speed wind and wind direction": "Joint display of speed wind (color) and wind direction (arrows), received by U and V components",
  "Atmospheric pressure on mean sea level": "Atmospheric pressure on mean sea level",
  "Air temperature at a height of 2 meters": "Air temperature at a height of 2 meters",
  "Temperature of a dew point": "Temperature of a dew point",
  "Soil temperature 0-10 сm": "Soil temperature 0-10 сm",
  "Soil temperature >10 сm": "Soil temperature >10 сm",
  "Relative humidity": "Relative humidity",
  "Cloudiness": "Cloudiness",
  "Start": "Start",
  "Finish": "Finish",
  "sputnic": "Satellite",
  "hybrid": "hybrid",
  "terrain": "terrain",
  "Engine load": "Engine load",
  "Show in list": "Show in list",
  "No location data": "No location data",
  "from": "From",
  "to": "To",
  "good day": "Good day",
  "User profile page": "User profile page",
  "Scaling": "Scaling",
  "Select the period of the show track": "Select the period of the show track",
  "No data for the this period": "No data for the this period",
  "Attention! Request for notifications is limited to": "Attention! Request for notifications is limited to",
  "days Please change the request parameters": "days. Please change the request parameters",
  "Track by mode": "Track by mode",
  "Track by speed": "Track by speed",
  "Track by load": "Track by load",
  "Delete track": "Delete track",
  "Track control panel (select track type, delete track)": "Track control panel (select track type, delete track)",
  "Geozone name": "Geozone name",
  "Time of entry": "Time of entry",
  "Departure time": "Departure time",
  "Time in geozone": "Time in geozone",
  "Current status of the vehicle": "Current status of the vehicle",
  "Make and model of technology": "Make and model of technology",
  "Level fuel": "Level fuel",
  "Track building": "Track building",
  "Track": "Track",
  "Enable / Disable the display of technology on the map": "Enable / Disable the display of technology on the map",
  "Total fuel spent on all available units": "Total fuel spent on all available units",
  "Fuel consumption by mode for all units": "Fuel consumption by mode for all units",
  "main diagram": "The total harvested / treated area and the diagram of the elapsed time for each mode for all machines.",
  "data for current day": "data for current day",
  "Show / hide geofzonee on map": "Show / hide geozone on map",
  "Select map tool": "Select map tool",
  "Show / hide geozone detail information": "Show / hide geozone detail information",
  "Geozone type": "Geozone type",
  "Delete": "Delete",
  "Delete geozone": "Delete geozone",
  "Edit geozone": "Edit geozone",
  "Enable geozone editing mode": "Enable geozone editing mode",
  "save": "Save",
  "cancel": "Cancel",
  "The range between the selected dates must not exceed 30 days": "The range between the selected dates must not exceed 30 days",
  "The interval selected is too large": "Too large interval selected",
  "Close": "Close",
  "Name": "Name",
  "Surname": "Surname",
  "middle name": "Middle name",
  role: "Position",
  "By mode": "By mode",
  "By days": "By days",
  "Analysis of working time by mode": "Analysis of working time by mode",
  "Analysis of working time by day": "Analysis of working time by day",
  "Graph of the analysis of working time by group / unit of equipment for the selected period": "Graph of the analysis of working time by group / unit of equipment for the selected period",
  "Graph of the analysis of working time for the group / unit of equipment for the selected period It is possible to turn on / off the display of modes": "Graph of the analysis of working time for the group / unit of equipment for the selected period. It is possible to turn on / off the display of modes",
  "Fuel in the tank at the beginning of the day": "Fuel in the tank at the beginning of the day",
  "Fuel in the tank at the end of the day": "Fuel in the tank at the end of the day",
  "Refills per day": "Refills per day",
  "Total consumption": "Total consumption",
  "Consumption in transport mode": "Consumption in transport mode",
  "Consumption in cleaning / work": "Consumption in cleaning / work",
  "Idle flow": "Idle consumption",
  "Average consumption": "Average consumption",
  "ICE working time": "ICE working time",
  "Time in transport mode": "Time in transport mode",
  "Time in cleaning / work": "Time in cleaning / work",
  "Idle time": "Idle time",
  "Table filling/drain": "Table filling/drain",
  "Volume": "Volume",
  "Other": "Other",
  "Combine Harvesters": "Combine Harvesters",
  "Forage Harvesters": "Forage Harvesters",
  "Power facilities": "Power facilities",
  "Tractors": "Tractors",
  "Treated area": "Treated area",
  "Distance traveled": "Distance traveled",
  "Engine running time": "Engine running time",
  "Fuel consumption": "Fuel consumption",
  "Average time performance": "Average time performance",
  "Yes": "Yes",
  "No": "No",
  "Work performance graph": "Work performance graph",
  "The choice of parameters for display on the graph (with the ability to change color)": "The choice of parameters for display on the graph (with the ability to change color)",
  "Select graphics": "Select graphics",
  "Types de rapports disponibles pour exportation dans un fichier Export disponible au format Excel": "Types de rapports disponibles pour exportation dans un fichier. Export disponible au format Excel",
  "Download": "Download",
  "The number of units in different modes in the household": "The number of units in different modes in the household",
  "Department of service vehicles": "Department of service vehicles",
  "Spare Parts Sales Department": "Spare Parts Sales Department",
  "Support Agrotronic": "Support Agrotronic",
  "Sprinklers": "Sprinklers",
  "Mower": "Mower",
  "Appearance vehicles": "Appearance vehicles",
  "Current technical information: percentage of fuel tank filling, current working status of the equipment, date and time of the last activity of the equipment": "Current technical information: percentage of fuel tank filling, current working status of the equipment, date and time of the last activity of the equipment",
  "Activity status of the tracking function (if selected, the tracking mode for this unit of equipment is turned on and transferred to the map)": "Activity status of the tracking function (if selected, the tracking mode for this unit of equipment is turned on and transferred to the map)",
  "Refresh": "Refresh",
  "Detailed parameters of the equipment": "Detailed parameters of the equipment",
  "List of user geozones with the search function by name": "List of user geozones with the search function by name",
  "List of households and units of user equipment with the search function by name": "List of households and units of user equipment with the search function by name",
  "Toggle viewing of objects on the map": "Toggle viewing of objects on the map",
  "Event name": "Event name",
  "Quantity for the period": "Quantity for the period",
  "Duration for the period": "Duration for the period",
  "Event start date": "Event start date",
  "Event start time": "Event start time",
  "Event end time": "Event end time",
  "Event duration": "Event duration",
  "Event location": "Event location",
  "Machine condition for the selected period": "Machine condition for the selected period",
  "Report Type Selection Menu": "Report Type Selection Menu",
  "Select one or more values": "Select one or more values",
  "Time period selection": "Time period selection",
  "Apply selected parameters in the filter": "Apply selected parameters in the filter",
  "Search the machine by serial number": "Search the machine by serial number",
  "Select one unit of equipment": "Select one unit of equipment",
  "Choice of equipment / group of equipment": "Choice of equipment / group of equipment",
  "Single parameter selection": "Single parameter selection",
  "wrong login or password": "wrong login or password",
  "Something went wrong. Error code": "Something went wrong. Error code",
  "Login": "Login",
  "Password": "Password",
  "Entry": "Entry",
  "Demo mode": "Demo mode",
  "Vechicle info": "Information about the car: car type icon (color: gray - “No connection”, black - “Off” (operation without ignition on)), red - “Simple with on” engine, blue - “Motion”, green - “Cleaning / Job\"). When you click on the model name or serial number, go to the page with detailed information about the car.",
  "The time of the first start of the engine for the current day": "The time of the first start of the engine for the current day",
  "Date and time of the last communication session": "Date and time of the last communication session",
  "Pie chart modes of operation for the current day": "Pie chart modes of operation for the current day",
  "Average speed of equipment in the mode of combining / working for the current day": "Average speed of equipment in the mode of combining / working for the current day",
  "Fuel consumption for the current day": "Fuel consumption for the current day",
  "Cleaned / treated area for the current day": "Cleaned / treated area for the current day",
  "Productivity of technology for the current day": "Productivity of technology for the current day",
  "Tracking Button": "Tracking Button",
  "Notification text": "Notification text",
  "Notification category": "Notification category",
  "Date and time start of notification": "Date and time start of notification",
  "Date and time finish of notification": "Date and time finish of notification",
  "Duration of notification": "Duration of notification",
  "Notification location": "Notification location",
  "List of farm equipment (indicating the number of monitored equipment)": "List of farm equipment (indicating the number of monitored equipment)",
  "dont machine": "Machine not found or not owned by user",
  "Timezone": "Timezone",
  "Phone": "Phone",
  "Phone number must be at least 11 characters": "Phone number must be at least 11 characters",
  "Are you sure you want to delete the geozone": "Are you sure you want to delete the geozone?",
  "Currently edit imported track do not support": "",
  "Productivity map": "Productivity map",
  "Productivity map wet KUK": "Productivity map KUK by wet weight",
  "Productivity map dry KUK": "Productivity map KUK by dry weight",
  "Productivity": "Productivity",
  "Create map": "Create map",
  "Download map": "Download map",
  "Part width": "Part width",
  "Moisture map ZUK": "Moisture map ZUK",
  "Moisture map KUK": "Moisture map KUK",
  "Moisture": "Moisture",
  "%": "%",
  "Choose vehicles": "Choose vehicles",
  "Create": "Create",
  "Planning": "RSM Router",
  "Agrotronic": "Agrotronic",
  "Alerts": "Alerts",
  "Fields": "Fields",
  "Production operations": "Production operations",
  "Technological maps": "Technological maps",
  "Alert tasks": "Alert tasks",
  "Inbox": "Inbox",
  "Catalogs": "Catalogs",
  "403 FORBIDDEN \"Too many machines selected\"": "Too many machines selected",

  "km/h": "km/h",
  "l": "l",
  "ha/h": "ha/h",
  "ha": "ha",
  "ms": "ms",
  "h": "h",
  "m": "м",
  "min": "min",
  "t/ha": "t/ha",
  "cwt/ha": "cwt/ha",
  // MAP
  "fullscreen": "Open map in full screen",
  "exit fullscreen": "Exit Full Screen",
  "zoom in": "Zoom in",
  'zoom out': "Zoom out",
  "Search the machine": "Search the machine",
  "Filter": "Filter",
  contact_information: "Contact information",
  iAcceptTerms: "I accept the",
  termsOfUse: "terms of use",
  deleteInfo: "Delete data",
  deleteInfoQuestion: "Are you sure you want to delete all data?",
  "Apply filter": "Apply filter",
  "of": "of",
  "Rows per page": "Rows per page",
  "Incorrect time period": "Incorrect time period",
  maxVehicleCountRequest: "Maximum number of machines to analyze:",
  "The weather info currently unvailable": "The weather info currently unvailable",
  "Create geozone for receive weather info": "Create geozone for receive weather info",
  "square": "Square",
  "Total amount of cultivated area": "Total amount of cultivated area",
  "All units": "All units",
  "unit": "unit",
  "unit_plural": "units",
  "Maintance": "Maintenance",
  "weather": "Weather",
  "Widgets": "Widgets",
  "antialiasing": "Antialiasing",
  "Move forward": "Move forward",
  "Neutral gear": "Neutral gear",
  "Back": "Back",
  "Parking": "Parking",
  "Deactivated": "Deactivated",
  "Activated in automatic NC hold mode": "NC hold",
  "Activated in automatic slice height maintenance mode": "Slice height maintenance",
  "Activated in automatic pressure maintenance mode": "Pressure maintenance",
  "download app": "Download the app",
  predictUnloading: 'Estimated unloading',
  "Download app for any device": 'Download the app for any device',
  "license plate": "License plate",
  brand: "Brand",
  allowed: "Allowed",
  forbidden: "Forbidden",
  transport: "Vehicle",
  transports: "Vehicles",
  sht: "Agricultural machinery",
  "Error load file: need type file zip": "Error load file: need type file .zip",
  "Error load file: need type file txt": "Error load file: need type file .txt",
  "Error load file": "Error load file.",
  "Need file types": "Valid file types:",
  "File downloaded": "File downloaded",
  "File read error": "File read error",
  "Coordinates are missing in the file": "Coordinates are missing in the file",
  "Shape file is not correct": "Shape file isn't correct",
  "Shape file have not supported type": "Shape file of this structure is not supported. In this version, only Polygon, MultiLineString, LineString, MultiPoint structures are supported",
  "num of unloading": "num of unloading",
  "osht": "OSHT",
  "download apk": "Download apk",
  "no data": "No data for the selected period",
  "report equipment": "Equipment operating time reports",
  "report performance": "Performance",
  "operating time": "Running time",
  "eh": "Hours",
  "aggregate": "aggregate",
  "aggregates": "Aggregates",
  "Unknown transport": "Unknown track",
  "Date and time": "Date and time",
  "Report": "Report",
  "during the period": "during the period",
  "Parameters": "Parameters",
  "Parameter": "Parameter",
  "Meaning": "Meaning",
  "Error creating file": "Error creating file",

  // Культуры
  "bean": "bean",
  "mustard": "mustard",
  "pea": "pea",
  "buckwheat": "buckwheat",
  "dactylis glomerata": "dactylis glomerata",
  "clover": "clover",
  "castor-oil plant": "castor-oil plant",
  "hemp": "hemp",
  "maize corn": "maize corn",
  "sesame": "sesame",
  "lucerne": "lucerne",
  "flax": "flax",
  "papaver": "papaver",
  "chick-pea": "chick-pea",
  "oat": "oat",
  "festuca pratensis": "festuca pratensis",
  "panicum": "panicum",
  "sunflower": "sunflower",
  "wheat": "wheat",
  "wheat winter": "wheat winter",
  "wheat spring": "wheat spring",
  "arrhenatherum": "arrhenatherum",
  "rape": "rape",
  "rice": "rice",
  "rye": "rye",
  "safflower": "safflower",
  "sugar-beet": "sugar-beet",
  "sorghum": "sorghum",
  "triticale": "triticale",
  "soy": "soy",
  "haricot": "haricot",
  "cotton": "cotton",
  "lentil": "lentil",
  "barley": "barley",
  "barley winter": "barley winter",
  "barley spring": "barley spring",
  "user1": "user1",
  "user2": "user2",
  "user3": "user3",
  "user4": "user4",
  "corn long wet": "corn long wet",
  "corn long": "corn long",
  "corn long dry": "corn long dry",
  "corn short wet": "corn short wet",
  "corn short": "corn short",
  "corn short dry": "corn short dry",
  "silage": "silage",
  "grass": "grass",
  "grass dry": "grass dry",
  "grass normal": "grass normal",
  "grass wet": "grass wet",
  "User manual Agrotronic Pilot": "User manual Agrotronic Pilot",
  "User manual Agrotronic": "User manual Agrotronic",
  "Agrotronic Administrator Tools Description": "Description of Agrotronic agro-industrial complex administrator tools",
  "User manual cruise control KUK": "User manual cruise control KUK",
  "Humidity sensor FH": "User's Guide Yield Mapping for chopper",
  "value must be": "Value must be",
  "greater than previous one": "greater than previous one",
  "less than following": "less than following",
  "value cannot be less than 0": "Value cannot be less than 0",
  "n/d": "no data",
  "about field": "about field",
  "about operator": "about operator",
  "about technique": "about technique",
  "about task": "about task",
  "about operation": "about operation",
  "operation": "Операция",
  "task": "Задание",
  "reporting service is not available": "Reporting service is not available",
  "Select data source": "When this function is turned on, yield and humidity maps in areas where there is no data from the Pilot's Agricultural Electronics RSM (Tablet) are built on the basis of data from the standard on-board system",
  "Select type of map": "Select type of map",
  "track service is not available": "Track service is not available",
  "service is not available": "Service is not available",
  "all sht": "All agricultural machinery",
  "operators": "Operators",
  "all fields": "All fields",
  "all operators": "All operators",
  "find operator": "Find an operator",
  "find field": "Find field",
  "find technique": "find a technique",
  "formed": "Formed",
  "performance report for the period": "Performance report for the period",
  "the report is generated with filters": "The report is generated with filters",
  "twenty-four hours": "day",
  "hours": "hours",
  "minutes": "minutes",
  "incl": "incl.",
  "total according to the report": "total according to the report",
  "driver report": "Driver report",
  "title": "Title",
  "date added": "Date added",
  "change history": "Change history",
  "changed to": "Changed to",
  "changed": "Changed",
  "date of change": "Date of change",
  "driver": "driver",
  "biometrics changed": "biometrics changed",
  "information": "Information",
  "driver information": "Driver information",
  "the report was generated without filters": "The report was generated without filters",
  "holding": "Holding",
  "Threshing sensor used": "The threshing sensor is used",
  "n period": "Period no.",
  "startHarvesting": "Beginning of harvesting",
  "endHarvesting": "End of harvesting",
  "periodHarvesting": "Harvesting period",
  "harvested agriculture": "Harvested crops",
  "density": "Density, kg / cubic meter",
  "Harvesting time for period": "Cleaning time for the period, h",
  "Harvested crop for period": "Harvested crop for the period, t",
  "Harvesting time": "Harvesting time",
  "Harvested crop": "Harvested crop",
  "Users": "Employees",
  "Farms": "Organizations",
  "AdminPanel": "Adm. panel",
  "Organizations types": "Organizations types",
  "Roles": "Roles",

  "You can request the code again via": "You can request the code again via",
  "Confirm": "Confirm",
  "Send SMS code": "Send SMS code",
  "SMS code": "SMS code",
  "Confirmation": "Confirmation",
  "Change phone": "Change phone",
  "To change the phone number, you must enter a confirmation code": "To change the phone number, you must enter a confirmation code, it will be sent in an SMS message to a new phone number",
  "Two-factor authentication": "Two-factor authentication",
  "To confirm the action": "To confirm the action, you need to enter the confirmation code, it is sent in an SMS message to your phone.",
  "To confirm the changes": "To confirm the changes, you need to enter a confirmation code, it will be sent in an SMS message to your phone.",
  "Error confirming code": "Confirmation error",
  "Error requesting code": "Error when requesting a confirmation code",
  "Error occurred during validation process": "An error occurred during validation",
  "Invalid address format": "The email address is in the wrong format",
  "The phone number is specified in the wrong format": "The phone number is specified in the wrong format",
  "Wrong code": "Wrong code",
  "Phone number successfully changed": "Phone number successfully changed",
  "Changes successfully saved": "Changes successfully saved",
  "Display machine data": "Display machine data",
  "Map Legend Management": "Map legend management",
  "Error loading calendar coloring pages": "Error loading calendar coloring pages",
  "Error while receiving data": "Error while receiving data",
  "Serial number": "Serial number",
  "Cocked": "Cocked",
  "Reset": "Reset",
  "Duration, hh:mm:ss": "Duration, hh:mm:ss",
  "hh:mm:ss": "hh:mm:ss",
  "Username is already in use, use a different one": "Username is already in use, use a different one",
  "Login can contain only numbers and Latin letters": "Login can contain only numbers and Latin letters",
  "Login cannot consist only of numbers": "Login cannot consist only of numbers",
  "Login cannot be less than 6 characters": "Login cannot be less than 6 characters",
  "Password can only contain numbers, latin letters and special characters": "Password can only contain numbers, Latin letters and special characters",
  "The password must contain at least 8 characters": "At least 8 characters, must contain uppercase and lowercase Latin letters, one digit and a special character",
  "Select a different report or change the filtering conditions": "Select another report or change the filtering conditions",
  "There is no data on which to build this report": "There is no data on which to build this report",
  "Enter the code sent to the number": "Enter the code sent to the number",
  "Enter the code sent to the address": "Enter the code sent to the address",
  "An SMS with a confirmation code will be sent to the phone": "An SMS with a confirmation code will be sent to the phone {number}",
  "To the address an email with a confirmation code will be sent": "An email with a confirmation code will be sent to the address {email}",
  "Only mobile numbers of the Russian Federation with the code +7": "Only mobile numbers of the Russian Federation with the code +7",
  "Phone Confirmation": "Phone Confirmation",
  "Email Confirmation": "Email Confirmation",
  "Enter code": "Enter Code",
  "On": "On",
  "Event": "Event",
  "Web": "Web",
  "CMC": "CMC",
  "Action Confirmation": "Action Confirmation",
  "To the address": "To the address",
  "confirmation code will be sent": "an email with a confirmation code will be sent",
  "To the phone": "To the phone",
  "SMS with a confirmation code will be sent": "an SMS with a confirmation code will be sent",
  "report unloading": "Report on uploads",

  //account block module warning
  "Logging in using enterprise accounts will be blocked in the near future": "⚠️ Logging in using enterprise accounts will be blocked in the near future",
  "Contact the administrator of the company or department to obtain a personal account": "Contact the administrator of the company or department to obtain a personal account",
  "The changes are aimed at improving data security and expanding usercapabilities": "The changes are aimed at improving data security and expanding user capabilities",
  "Continue working": "Continue working",
  "You have changed the display of machine data": "You have changed the display of machine data. Update the information so that the yield data is displayed correctly.",
  "Select at least one machine to build a field field report": "Select at least one machine to build a field yield report",
  "Mapped": "Mapped",
  "Avg yield": "Average yield",
  "Total yield": "Harvested crop",
  "The area of the geofence without taking into account the relief of the earth": "The area of the geofence without taking into account the relief of the earth",
  "The area of the geofence for which there is data on yield": "The area of the geofence for which there is data on yield",
  "Average yield of the mapped port of the geofence": "Average yield of the mapped part of the geofence",
  "Indicative (estimated) data obtained on the yield of the field": "Indicative (estimated) data obtained on the yield of the field",
  "Download the field yield report": "Download the field yield report",
  "T": "t",
  "Field Yield Report": "Field Yield Report",
  "Equipment (Equipment that was in the defense during the specified period)": "Equipment (Equipment that was in the geofence during the specified period)",
  "Map type": "Map type",
  "Productivity map ZUK": "Productivity map ZUK",
  "Appearance": "Appearance",
  "Elimination": "Elimination",
  "Tire pressure": "Tire pressure",
  "Auto-guidance system": "Auto-guidance system",
  "View location is not available, the machine has not yet sent the coordinates": "View location is not available, the machine has not yet sent the coordinates",
  "pcs": "pc",
  "RSM User Manual Auto-unloading KUK": "RSM User Manual Auto-unloading KUK",
  "Unknown agriculture": "Unknown agriculture",
  'Use system time': 'Использовать системное время',
  'The time zone will be automatically synchronized': 'The time zone in AGROTRONIKA will be automatically synchronized with the time on your device'
};
