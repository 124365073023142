import { action, configure, makeObservable, observable } from 'mobx'
import { TPeriod } from 'types'

// TODO: move out into components Date object later
function isSame(date1: Date, date2: Date, unit?: 'year' | 'month' | 'day'): boolean {
  if(unit === 'year') {
    return date1.getFullYear() === date2.getFullYear()
  } else if(unit === 'month') {
    return date1.getMonth() === date2.getMonth() && isSame(date1, date2, 'year')
  } else {
    return date1.getDate() === date2.getDate() && isSame(date1, date2, 'month')
  }
}

const weekPeriod = () => [new Date().subtract(1, 'week'), new Date().endOf('day')]
const monthPeriod = () => [new Date().subtract(1, 'month'), new Date().endOf('day')]
const todayPeriod = () => [new Date().startOf('day'), new Date().endOf('day')]

configure({ enforceActions: 'observed' })

export class PeriodStore {
  @observable value = weekPeriod()
  @observable period: TPeriod = 'today'

  get start() {
    return this.value[0]
  }

  get end() {
    return this.value[1]
  }

  constructor(period: TPeriod) {
    makeObservable(this)
    this.setPeriod(period)
  }

  @action.bound
  setWeek() {
    this.value = weekPeriod()
  }

  @action.bound
  setMonth() {
    this.value = monthPeriod()
  }

  @action.bound
  setToday() {
    this.value = todayPeriod()
  }

  @action.bound
  setValue(newRange: [Date, Date]) {
    this.value = newRange

    // Выбираем тип периода по датам
    // Если конец периода - сегодня
    if(isSame(newRange[1], weekPeriod()[1], 'day')) {
      // Если период последняя неделя
      if(isSame( newRange[0], weekPeriod()[0], 'day')) {
        this.period = 'week'
        return
      }

      // Если начало периодна месяц
      else if(isSame(newRange[0], monthPeriod()[0], 'day')) {
        this.period = 'month'
        return
      }
    }

    this.period = 'range'
  }

  @action.bound
  setPeriod(value: TPeriod) {
    this.period = value

    if(value === 'week') {
      this.setWeek()
    } else if(value === 'month')
    {
      this.setMonth()
    } else if(value === 'today') {
      this.setToday()
    }
  }
}
